var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"min-w-1400",class:{
  navbar: (_vm.$route.path === '/' || _vm.$route.path === '/about'),
  'other-navbar': (_vm.$route.path !== '/' && _vm.$route.path !== '/about'),
}},[_c('div',{staticClass:"max-w-1400",class:{
    secondBox: (_vm.$route.path === '/' || _vm.$route.path === '/about'),
    'other-secondBox': (_vm.$route.path !== '/' && _vm.$route.path !== '/about'),
  }},[_vm._m(0),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"Li1",class:{ 'active-link': _vm.isActive('/') }},[_c('router-link',{staticClass:"nav-link1",attrs:{"to":"/"}},[_c('span',{staticClass:"routeText1"},[_vm._v("เกี่ยวกับบริษัท")])])],1),_c('li',{staticClass:"Li2",class:{ 'active-link': _vm.$route.path === '/about' }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/about"}},[_c('span',{staticClass:"routeText2"},[_vm._v("เกี่ยวกับผลิตภัณฑ์")])])],1),_c('li',{staticClass:"Li3",class:{ 'active-link': _vm.$route.path === '/question' }},[_c('router-link',{staticClass:"nav-link3",attrs:{"to":"/question"}},[_c('span',{staticClass:"routeText3"},[_vm._v("ศูนย์ช่วยเหลือ")])])],1),_c('li',{staticClass:"Li4",class:{ 'active-link': _vm.$route.path === '/protocol' }},[_vm._m(1)])])]),_c('div',{staticStyle:{"height":"130px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('span',{staticClass:"title"},[_vm._v("GADZILLA COMPANY LIMITED")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link4",attrs:{"href":"https://she.finlonecashprogadapp.com/g9D0C7H/h7A2p6a1A.html","target":"_blank"}},[_c('span',{staticClass:"routeText4"},[_vm._v("ข้อตกลงความเป็นส่วนตัว")])])
}]

export { render, staticRenderFns }